@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Lato", "DM Sans", "Karla", "BlinkMacSystemFont", "Barlow", system-ui, sans-serif, "Segoe UI",
      Helvetica, Arial, sans-serif;
    scroll-behavior: smooth;
  }
}
.progress-bar-inner {
  animation: animate-bar 24s linear forwards;
  animation-play-state: paused;
}
@keyframes animate-bar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes show-data {
  50% {
    transform: translateY(-10rem);
  }
  100% {
    transform: translateY(-7rem);
  }
}

@keyframes remove-overflow {
  to {
    overflow: initial;
  }
}

.progress-bar-inner:hover {
  animation-play-state: paused;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-appearance: none;
  @apply bg-purple-900;
}
